import * as React from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {Paper} from "@mui/material";

import BackgroundImage from "./background.jpg"

const styles = {
    paperContainer: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh"
    },
    card: {
        backgroundColor: 'gray',
        opacity: "70%"
    },
    footer: {
        bgcolor: 'gray',
        color: 'white',
        opacity: "40%",
        p: 6,
        position: 'fixed',
        bottom: 0,
        width: '100%'
    }
}

function Copyright() {
  return (
      <Typography variant="body2" color="inherit" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://toficonsults.com">
          Tofi Consults
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const offerings = [
    {
        title: "Online Stores and E-Commerce",
        description: <>
            Helping you reach your online customers with our high-quality ecommerce offerings using payment providers you know!
        </>,
        href: "/on/ecommerce"
    },
    {
        title: "Fullstack application development",
        description: "Get your software ideas realised.",
        href: "/on/dev"
    },
    {
        title: "Other",
        description: "Consultation on computer science, software engineering and related work.",
        href: "/on/other"
    }
]

const theme = createTheme();

export default function Album() {
  return (
      <Paper style={styles.paperContainer}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <main>
              {/* Hero unit */}
              <Box
                  sx={{
                    bgcolor: 'gray',
                    opacity: '40%',
                    pt: 8,
                    pb: 6,
                  }}
              >
                <Container maxWidth="sm">
                  <Typography
                      component="h1"
                      variant="h2"
                      align="center"
                      color="text.primary"
                      gutterBottom
                  >
                    Your consultant developer.
                  </Typography>
                  <Typography variant="h5" align="center" color="text.secondary" paragraph>
                    Need a software developer?
                      <br/>
                    <Button sx={{ bgcolor: 'white', opacity: "100%" }} href={"mailto://admin@toficonsults.com"}>
                        Send me an email
                    </Button>
                  </Typography>
                </Container>
              </Box>
              <Container sx={{ py: 8 }} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                  {offerings.map(({ title, description, href }, card) => (
                      <Grid item key={card} xs={12} sm={6} md={4}>
                        <Card
                            style={styles.card}
                            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                        >
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {title}
                            </Typography>
                            <Typography>
                                {description}
                            </Typography>
                          </CardContent>
                          {/*<CardActions>*/}
                          {/*  <Button size="small" href={href}>View</Button>*/}
                          {/*</CardActions>*/}
                        </Card>
                      </Grid>
                  ))}
                </Grid>
              </Container>
            </main>
            {/* Footer */}
            <Box sx={styles.footer} component="footer">
              <Copyright />
            </Box>
            {/* End footer */}
        </ThemeProvider>
      </Paper>
  );
}
